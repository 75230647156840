import React, { useState, useEffect } from 'react';
import '../../assets/css/footer.css';
import linkedin from '../../assets/img/pages/linkedin.png';

const Footer = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);
  return (
    <>
      {language === 'Spanish' ? (
        <footer className='footer'>
          <div className='container-fluid-footer'>
            <div className='row'>
              <div className='col-12 bold image_footer_1 footer-text text-white text-align-center'>
                <a
                  href='/aviso-de-privacidad'
                  target='_blank'
                  className='shared content-footer-30'
                  rel='noreferrer'>
                  Aviso de Privacidad
                </a>
                <span> BPO & TECHNOLOGY</span>
                <a
                  href='https://mx.linkedin.com/company/bpo-tecnologia?original_referer=https%3A%2F%2Fwww.bing.com%2F'
                  target='_blank'
                  className='shared content-footer-30'
                  rel='noreferrer'>
                  <img src={linkedin} width='20' alt='linkedin' />
                </a>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className='footer'>
          <div className='container-fluid-footer'>
            <div className='row'>
              <div className='col-12 bold image_footer_1 footer-text text-white text-align-center'>
                <a
                  href='/aviso-de-privacidad'
                  target='_blank'
                  className='shared content-footer-30'
                  rel='noreferrer'>
                  Notice of Privacy
                </a>
                <span> BPO & TECHNOLOGY</span>
                <a
                  href='https://mx.linkedin.com/company/bpo-tecnologia?original_referer=https%3A%2F%2Fwww.bing.com%2F'
                  target='_blank'
                  className='shared content-footer-30'
                  rel='noreferrer'>
                  <img src={linkedin} width='20' alt='linkedin' />
                </a>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
