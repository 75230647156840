import Carousel from 'react-bootstrap/Carousel';
import '../../../../assets/css/atoms/sections.css';
import Plataforma1 from '../../../../assets/img/pages/tools/plataforma1.png';
import Plataforma2 from '../../../../assets/img/pages/tools/plataforma2.png';
import Plataforma3 from '../../../../assets/img/pages/tools/plataforma3.png';
import Plataforma4 from '../../../../assets/img/pages/tools/plataforma4.png';
import Plataforma5 from '../../../../assets/img/pages/tools/plataforma5.png';
import Plataforma51 from '../../../../assets/img/pages/tools/plataforma5.1.png';
import Plataforma6 from '../../../../assets/img/pages/tools/plataforma6.png';
import Plataforma7 from '../../../../assets/img/pages/tools/plataforma7.png';
import Plataforma8 from '../../../../assets/img/pages/tools/plataforma8.png';
import Plataforma9 from '../../../../assets/img/pages/tools/plataforma9.png';
import Plataforma10 from '../../../../assets/img/pages/tools/plataforma10.png';
import Plataforma11 from '../../../../assets/img/pages/tools/plataforma11.png';
import Plataforma12 from '../../../../assets/img/pages/tools/plataforma12.png';
import Plataforma13 from '../../../../assets/img/pages/tools/plataforma13.png';

function CarouselTools() {
  return (
    <div>
      <div className='carousel-tools-web'>
        <Carousel interval={2000}>
          <Carousel.Item>
            <div className='row  mm-0 row-client-2'>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma1}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma2}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma3}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma4}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='row mm-0 row-client-2'>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma5}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma51}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>

              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma6}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>

              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma7}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='row mm-0 row-client-2'>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma8}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma9}
                      alt='service'
                      className='card-image-generic-response image-data-dog'
                    />
                  </div>
                </div>
              </div>

              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma10}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>

              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma11}
                      alt='service'
                      className='card-image-generic-response'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='row mm-0 row-client-2'>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma12}
                      alt='service'
                      className='card-image-generic-response image-data-docker'
                    />
                  </div>
                </div>
              </div>
              <div class='col-sm-3'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Plataforma13}
                      alt='service'
                      className='card-image-generic-response image-data-oddo'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className='carousel-tools-mobile'>
        <Carousel interval={1200}>
          <Carousel.Item>
            <div class='row mb-3 align-content-center carusel-row'>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma1}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma2}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class='row mb-3 align-content-center carusel-row carousel-tools-mobile-2'>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma3}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma4}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class='row mb-3 align-content-center carusel-row'>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma5}
                  alt='service'
                  className='card-image-generic-response img-carusel-datadog'
                />
              </div>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma6}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class='row mb-3 align-content-center carusel-row'>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma7}
                  alt='service'
                  className='card-image-generic-response '
                />
              </div>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma8}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class='row mb-3 align-content-center carusel-row'>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma9}
                  alt='service'
                  className='card-image-generic-response img-carusel-datadog'
                />
              </div>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma10}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class='row mb-3 align-content-center carusel-row carusel-docker'>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma11}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma12}
                  alt='service'
                  className='card-image-generic-response  img-carusel-docker'
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class='row mb-3 align-content-center carusel-row carousel-oddo'>
              <div class='col-6'>
                {' '}
                <img
                  src={Plataforma13}
                  alt='service'
                  className='card-image-generic-response'
                />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default CarouselTools;
