import React from 'react';
import Avisodeprivacidad from '../components/commons/sections/avisodeprivacidad';
import Layout from '../components/layout/layout';

const AvisodePrivacidad = () => {
  return (
    <Layout>
      <div className='bg-white'>
        <Avisodeprivacidad />
      </div>
    </Layout>
  );
};

export default AvisodePrivacidad;
