import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/css/general.css';

import Home from './views/Home';
import Services from './views/Services';
import Contact from './views/Contact';
import WhyUs from './views/Whyus';
import Clients from './views/Clients';
import Solutions from './views/Solutions';
import Integration from './views/Integration';
import AvisodePrivacidad from './views/AvisodePrivacidad';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/avisa-' element={<WhyUs />} />
        <Route path='/contacto' element={<Contact />} />
        <Route path='/soluciones' element={<Solutions />} />
        <Route path='/clientes' element={<Clients />} />
        <Route path='/servicios' element={<Services />} />
        <Route path='/integracion' element={<Integration />} />
        <Route path='/aviso-de-privacidad' element={<AvisodePrivacidad />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
