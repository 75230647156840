import React from 'react';
import './../../../assets/css/atoms/cards.css';
const CardsGeneric1 = ({ title, text, src, link }) => {
  return (
    <div class='col-lg-6'>
      <a href={link}>
        <div class='card flex-md-row mb-4 box-shadow h-md-250 card-generic1'>
          <img src={src} alt='solutions' className='card-image-generic1' />
          <div class='card-body d-flex flex-column align-items-start'>
            <h5 class='card-title-generic-1'>{title}</h5>
            <p class='card-text-generic-1 mb-auto'>{text}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default CardsGeneric1;
