import Exploration from '../components/commons/sections/home/exploration';
import First from '../components/commons/sections/home/first';
import Nosotros from '../components/commons/sections/home/nosotros';
import Layout from '../components/layout/layout';
import Servicios from '../components/commons/sections/home/servicios';
import Clientes from '../components/commons/sections/home/clientes';
import Soluciones from '../components/commons/sections/home/soluciones';
import Contacto from '../components/commons/sections/home/contacto';

const Home = () => {
  return (
    <Layout>
      <First />
      <div className='background-fixed'>
        <Nosotros />
        <Servicios />
      </div>
      <div className='background-gray'>
        <Soluciones />
      </div>

      <div className='background-fixed'>
        <Exploration />
      </div>
      <Clientes />
      <div className='background-fixed'>
        <Contacto />
      </div>
    </Layout>
  );
};

export default Home;
