import React from 'react';
import './style.css';

const Efect1 = ({ value }) => {
  var index = 0;
  var text = `${value}`;
  var speed = 50;

  function textEffect() {
    if (index < text.length) {
      document.getElementById('effect').innerHTML += text.charAt(index);
      index++;
    }
  }
  setTimeout(textEffect, speed);

  return (
    <>
      <div class='container-effect-1 pt-2'>
        <div class='wrapper'>
          <div class='typewriter'>
            <h1>{value}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Efect1;
