import React from 'react';
import Layout from '../components/layout/layout';
import Because from '../components/commons/sections/whyus/because';
import Contact from '../components/commons/sections/whyus/contact';
import Result from '../components/commons/sections/whyus/result';

const WhyUs = () => {
  return (
    <Layout>
      <div>
        <Because />
        <Result />
        <Contact />
      </div>
    </Layout>
  );
};

export default WhyUs;
