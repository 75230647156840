import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';
import dayjs from 'dayjs';

export const savePersonName = (
  nombre,
  apellido,
  email,
  telefono,
  compania,
  posicion,
  mensaje,
  terminos
) => {
  const fechaHoy = dayjs();
  addDoc(collection(db, 'contacto'), {
    nombre,
    apellido,
    email,
    telefono,
    compania,
    posicion,
    mensaje,
    terminos,
    fechadeCreacion: fechaHoy.format('YYYY-MM-DD HH:MM'),
  });
};

const itemsCollection = collection(db, 'items');

// CREATE
export const createItem = (obj) => {
  return addDoc(itemsCollection, obj).id;
};
