import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Container, Offcanvas } from 'react-bootstrap';
import { Logo } from '../atoms/logo';
import '../../assets/css/header.css';

const Header = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;

  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);

  const handleClose = () => {
    var rojos = document.getElementsByClassName('offcanvas offcanvas-end show');
    for (var i = 0; i < rojos.length; i++) {
      rojos[i].classList.remove('show');
    }
    var verdes = document.getElementsByClassName(
      'fade offcanvas-backdrop show'
    );
    for (var e = 0; e < verdes.length; e++) {
      verdes[e].classList.remove('show');
    }
    var azules = document.getElementsByClassName('modal-open');
    for (var p = 0; p < azules.length; p++) {
      azules[p].removeAttribute('style');
    }
  };
  return (
    <div>
      {language === 'Spanish' ? (
        <>
          {[false].map((expand) => (
            <Navbar key={expand} expand='lg' className='bg-black fixed-top'>
              <Container fluid>
                <Navbar.Brand href='/'>
                  <Logo />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement='end'>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}>
                      Menu
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className='justify-content-end flex-grow-1 pe-3'>
                      <Nav.Link href='/'>
                        <p className='nav-bar-p'>Inicio</p>
                      </Nav.Link>
                      <Nav.Link href='#nosotros' onClick={handleClose}>
                        <p className='nav-bar-p'>Nosotros</p>
                      </Nav.Link>
                      <Nav.Link href='#servicios' onClick={handleClose}>
                        <p className='nav-bar-p'>Servicios</p>
                      </Nav.Link>

                      <Nav.Link href='#soluciones' onClick={handleClose}>
                        <p className='nav-bar-p'>Soluciones</p>
                      </Nav.Link>
                      <Nav.Link href='#clientes' onClick={handleClose}>
                        <p className='nav-bar-p'>Nuestros clientes</p>
                      </Nav.Link>
                      <Nav.Link href='#contacto' onClick={handleClose}>
                        <p className='nav-bar-p'>Contacto</p>
                      </Nav.Link>
                      <Nav.Link
                        href='https://calendly.com/bpotecnologia?month=2022-07'
                        className='btn-principal'
                        onClick={handleClose}
                        target='_blank'>
                        <p className='nav-bar-p text-white'>
                          VALORACION GRATIS
                        </p>
                      </Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </>
      ) : (
        <>
          {[false].map((expand) => (
            <Navbar key={expand} expand='lg' className='bg-black fixed-top'>
              <Container fluid>
                <Navbar.Brand href='/'>
                  <Logo />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement='end'>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}>
                      Main
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className='justify-content-end flex-grow-1 pe-3'>
                      <Nav.Link href='/'>
                        <p className='nav-bar-p'>Home</p>
                      </Nav.Link>
                      <Nav.Link href='#nosotros' onClick={handleClose}>
                        <p className='nav-bar-p'>About Us</p>
                      </Nav.Link>
                      <Nav.Link href='#servicios' onClick={handleClose}>
                        <p className='nav-bar-p'>Services</p>
                      </Nav.Link>
                      <Nav.Link href='#soluciones' onClick={handleClose}>
                        <p className='nav-bar-p'>Solutions</p>
                      </Nav.Link>
                      <Nav.Link href='#contacto' onClick={handleClose}>
                        <p className='nav-bar-p'>Contact</p>
                      </Nav.Link>
                      <Nav.Link
                        href='https://calendly.com/bpotecnologia?month=2022-07'
                        className='btn-principal'
                        onClick={handleClose}>
                        <p className='nav-bar-p text-white'>FREE ASSESSMENT</p>
                      </Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </>
      )}
    </div>
  );
};

export default Header;
