import React from 'react';
import '../../../../assets/css/atoms/sections.css';
import Titleh2 from '../../../atoms/title/titleh2';

const Because = () => {
  return (
    <div className='background-because pb-2'>
      <div className='container-fluid mt-5 pt-52'>
        <Titleh2
          text='¿Por qué Silver Creek Insights?'
          className='title-because mb-4'
        />
        <p className='text-white text-because'>
          Simplificamos la exploración de datos para obtener información
          crítica, mejorando la experiencia de principio a fin.
          <br />
          <br /> Diseñamos hermosas visualizaciones para que su equipo entienda
          fácilmente los datos clave. Conectamos sus fuentes de datos,
          agilizamos los flujos de trabajo y modernizamos su infraestructura en
          la nube con la mejor tecnología disponible. Juntamos todas las piezas
          y somos responsables del éxito total de la solución, y garantizamos
          los resultados.
          <br />
          <br />
          Al evaluar su entorno actual, sus objetivos y su visión de éxito,
          creamos soluciones que impactan positivamente en el negocio. Más que
          soluciones técnicas, nuestro equipo te ayuda a construir una verdadera
          cultura de Data-Insights.
          <br />
          <br />
          Habilitado con estos conocimientos críticos, su equipo puede tomar
          mejores decisiones, operar de manera más eficiente, vencer a la
          competencia y servir mejor a sus clientes.
          <br />
          <br />
          <span className='bold'>Nuestras especialidades:</span> análisis de
          datos, visualización y exploración de datos, inteligencia comercial,
          integración, automatización de flujos de trabajo de datos,
          canalizaciones de datos, ELT/ELT, almacenamiento de datos, gobierno de
          datos, modernización de la nube, análisis avanzado, IA y ML,
          capacitación y habilitación, conocimientos de datos cultura.
          <br />
          <br />
          <p className='bold mb-4'>
            Beneficios clave de trabajar con nosotros:
          </p>
          <ul className='ul-because'>
            <li>Resultados garantizados, entrega más rápida - 10X ROI</li>
            <li>
              Conocemos el análisis de datos: nuestro equipo tiene una amplia
              experiencia empresarial para resolver sus problemas
            </li>
            <li>
              Somos dueños del problema de los datos: responsabilidad total
              desde la fuente hasta el almacén de datos y la visualización
            </li>
            <li>
              Cree soluciones fundamentales que escalan para la optimización
              continua
            </li>
            <li>
              Cree una verdadera cultura de conocimiento de los datos con
              análisis sencillos de autoservicio
            </li>
            <li>
              Elimine costosos cuellos de botella de datos sin tener que
              codificar
            </li>
            <li>
              Quite el silo y confíe en sus datos: automatice los flujos de
              trabajo de datos en una infraestructura de nube gobernada de forma
              segura
            </li>
            <li>
              Nos asociamos con las mejores tecnologías: elimine la confusión de
              ventas de tecnología
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Because;
