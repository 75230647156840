import React from 'react';
import '../../../../assets/css/atoms/sections.css';
import '../../../../assets/css/general.css';
import Efect1 from '../../../atoms/effectsText/efect1/efect1';
import CardsGeneric1 from '../../../atoms/cards/cardsgeneric1';
import Solution1 from '../../../../assets/img/pages/Thuban_integracion.png';
import Solution2 from '../../../../assets/img/pages/320px-Kafka_Batch_processing.jpg';
import Solution3 from '../../../../assets/img/pages/call-customer-support-man-silhouette-head-face-headset-consultant-help-business-line-contact-operator-phone-callback-sales-secretary-corporate-.jpg';
import Solution4 from '../../../../assets/img/pages/support-help-call-center-headset-royalty-free-thumbnail.jpg';
import Solution5 from '../../../../assets/img/pages/man-5946820_960_720.jpg';
import Solution6 from '../../../../assets/img/pages/business-time-appointment-planning-clock-support-call-centre-thumbnail.jpg';

const Contens = () => {
  return (
    <div>
      <Efect1 value='INTEGRACIÓN de Aplicaciones, datos  y RPA' />
      <div class='container-static'>
        <div class='row content-product ml-0 mr-0 col-sm-12'>
          <CardsGeneric1
            src={Solution1}
            title='Integración Aplicaciones'
            text='Integración Web, Apps y datos internos y externos'
            link='/'
          />
          <CardsGeneric1
            src={Solution2}
            title='Datalakes'
            text='Datos operativos y datos sensores'
            link='/'
          />
          <CardsGeneric1
            src={Solution3}
            title='Asistente digital'
            text='Ventas y atención a clientes'
            link='/'
          />
          <CardsGeneric1
            src={Solution4}
            title='Plataforma de capacitación, elearning, aula virtual y digital'
            link='/'
          />
          <CardsGeneric1
            src={Solution5}
            title='Asistente digital'
            text='Soporte y mesa de ayuda'
            link='/'
          />
          <CardsGeneric1
            src={Solution5}
            title='Integración Reconocimiento facial  '
            text='Marketing en tiempo real'
            link='/'
          />
          <CardsGeneric1
            src={Solution6}
            title='Asistente digital'
            text='Asistente de Gestión en HomeOffices'
            link='/'
          />
        </div>
      </div>
    </div>
  );
};

export default Contens;
