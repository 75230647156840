import React from 'react';
import '../../../assets/css/atoms/buttons.css';

const Button = ({
  className = 'button-basic',
  text = 'AGENDAR UNA EVALUACIÓN  GRATUITA',
  href = 'https://calendly.com/bpotecnologia?month=2022-07',
  divclassName = 'text-center',
}) => {
  return (
    <div className={divclassName}>
      <a className={className} href={href}>
        {text}
      </a>
    </div>
  );
};

export default Button;
