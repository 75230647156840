import React, { useState, useEffect } from 'react';
import '../../../../assets/css/atoms/sections.css';
import '../../../../assets/css/atoms/cards.css';
import '../../../../assets/css/atoms/title.css';

const Exploration = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);
  return (
    <>
      {language === 'Spanish' ? (
        <div className='text-center mt-3 container-fluid animate__animated animate__fadeIn animate__bounce animate__delay-3s'>
          <h1 className='title-white title-exploration'>
            Cuentas con nuestro compromiso
            <p className='title-pink'>
              para buscar un solución eficiente con el costo adecuado
            </p>
          </h1>
          <br />
          <h3 className='subtitle-exploration mb-4'>
            Cómo lo hacemos
            <span className='text-bold'> posible en tiempo record</span> <br />
            <br /> Trabajamos mediante
            <span className='text-bold'> proyectos mínimos viables </span>que
            permiten demostrar nuestro valor y compromiso con tus
            <span className='text-bold'> necesidades</span>
          </h3>
          <div className='text-exploration mb-4 mt-5 pb-5'>
            <p>1. Agendas una llamada con un experto</p>
            <p>2. Te presentamos alternativas de solución</p>
            <p>3. Se planea y priorizan las entregas de funcionalidades</p>
            <p>4. Uno de nuestros expertos asegura el éxito tu proyecto</p>
            <p id='clientes'>
              5. Se entrega la documentación y soporte al equipo responsable del
              proyecto
            </p>
          </div>
        </div>
      ) : (
        <div className='text-center mt-3 container-fluid animate__animated animate__fadeIn animate__bounce animate__delay-3s'>
          <h1 className='title-white title-exploration'>
            You can count on our commitment
            <p className='title-pink'>
              to find an efficient solution at the right cost
            </p>
          </h1>
          <br />
          <h3 className='subtitle-exploration mb-4'>
            How we make it
            <span className='text-bold'> possible in record time</span> <br />
            <br /> We work through minimum
            <span className='text-bold'> viable projects </span>that allow us to
            demonstrate our value and commitment to your
            <span className='text-bold'> needs.</span>
          </h3>
          <div className='text-exploration mb-4 mt-5 pb-5'>
            <p>1. You schedule a call with an expert.</p>
            <p>2. We present you alternative solutions.</p>
            <p>3. We plan and prioritize the delivery of functionalities.</p>
            <p>4. One of our experts ensures the success of your project.</p>
            <p id='clientes'>
              5. Documentation and support is delivered to the project team.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Exploration;
