// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCVdNQroHW8u5wWdZLPcCZIpQfmYns8pKQ',
  authDomain: 'bpo-codebase.firebaseapp.com',
  projectId: 'bpo-codebase',
  storageBucket: 'bpo-codebase.appspot.com',
  messagingSenderId: '744465729502',
  appId: '1:744465729502:web:4c1dc690cab789862a7f4f',
  measurementId: 'G-F3M3NTVZ0X',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export default app;
