import React from 'react';
import '../../../assets/css/atoms/cards.css';

export const Cards1 = ({
  className = 'col-md-3 cards-1',
  classTitle = 'img-card-1',
  classText = 'text-card-1',
  text = 'text',
  width,
  src,
}) => {
  return (
    <div className={className}>
      <img className={classTitle} src={src} width={width} alt='card1' />
      <p className={classText}>{text}</p>
    </div>
  );
};
