import React, { useState, useEffect } from 'react';
import '../../../../assets/css/atoms/sections.css';
import Service1 from '../../../../assets/img/pages/software4.png';
import Service2 from '../../../../assets/img/pages/tecnologia.png';
import Service3 from '../../../../assets/img/pages/saas.png';

const Servicios = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);
  return (
    <>
      {language === 'Spanish' ? (
        <div
          className='text-center mt-3 container-fluid  animate__animated animate__zoomInLeft animate__bounce animate__delay-3s'
          id='servicios'>
          <div className='mb-5 mt-5'>
            <h1 className='text-white text-left bold text-center'>
              <p className='pt-121 ptm-18'>
                <span className='pink'> 3 pilares tecnológicos </span> para
                acelerar la digitalización
              </p>
            </h1>
            <div className='row mt-4 mm-0'>
              <div class='col-sm-4'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Service1}
                      alt='service'
                      className='card-image-generic-service-1'
                    />
                    <h5 class='card-title-generic2'>Fábrica de Software</h5>
                    <p class='card-generic-text2'>
                      <span className='text-bold'>Disposición inmediata </span>{' '}
                      de un equipo multidisciplinario altamente integrado, capaz
                      de
                      <span className='text-bold'> sumarse </span> a las
                      necesidades de sus proyectos, adaptándose e
                      <span className='text-bold'> impulsando </span> las
                      mejores prácticas y tecnologías de la industria.
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-sm-4'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Service2}
                      alt='service'
                      className='card-image-generic-service'
                    />
                    <h5 class='card-title-generic2'>Desarrollo de Software</h5>
                    <p class='card-generic-text2'>
                      <span className='text-bold'>
                        Definición, arquitectura y desarrollo{' '}
                      </span>
                      de soluciones digitales altamente escalables, enfocadas en
                      entregar{' '}
                      <span className='text-bold'>valor a su negocio </span>en
                      corto plazo, con costes optimizados y planeación de
                      crecimiento a<span className='text-bold'> futuro.</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-sm-4'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Service3}
                      alt='service'
                      className='card-image-generic-service'
                    />
                    <h5 class='card-title-generic2'>Software como servicio</h5>
                    <p class='card-generic-text2'>
                      <span className='text-bold'>
                        Integración, personalización y capacitación{' '}
                      </span>
                      de soluciones digitales ya construidas, enfocadas en
                      entregar
                      <span className='text-bold'> valor a su negocio </span>en
                      corto plazo, con costes optimizados y planeación de
                      crecimiento a
                      <span className='text-bold'> futuro. Buscamos</span>
                      <span className='text-bold'> plataformas </span> como
                      servicios o soluciones como servicios
                      <span className='text-bold'> (PaaS, SaaS).</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className='text-center mt-3 container-fluid  animate__animated animate__zoomInLeft animate__bounce animate__delay-3s'
          id='servicios'>
          <div className='mb-5 mt-5'>
            <h1 className='text-white text-left bold text-center'>
              <p className='pt-121 ptm-18'>
                <span className='pink'>3 technological cornerstones </span>
                to accelerate digitization
              </p>
            </h1>
            <div className='row mt-4 mm-0'>
              <div class='col-sm-4'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Service1}
                      alt='service'
                      className='card-image-generic-service-1'
                    />
                    <h5 class='card-title-generic2'>Software Factory</h5>
                    <p class='card-generic-text2'>
                      <span className='text-bold'>Immediate availability </span>{' '}
                      of a highly integrated multidisciplinary team,
                      <span className='text-bold'> sumarse </span> capable of
                      meeting the needs of your
                      <span className='text-bold'> projects </span> , adapting
                      and promoting the best practices and technologies in the
                      industry.
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-sm-4'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Service2}
                      alt='service'
                      className='card-image-generic-service'
                    />
                    <h5 class='card-title-generic2'>Software Development</h5>
                    <p class='card-generic-text2'>
                      <span className='text-bold'>
                        Definition, architecture and development
                      </span>
                      of highly scalable digital solutions focused on delivering
                      <span className='text-bold'>value to your business </span>
                      in the short term, with optimized costs and{' '}
                      <span className='text-bold'> future </span> growth
                      planning.
                    </p>
                  </div>
                </div>
              </div>
              <div class='col-sm-4'>
                <div class='card card-generic2 background-transparent'>
                  <div class='card-body'>
                    <img
                      src={Service3}
                      alt='service'
                      className='card-image-generic-service'
                    />
                    <h5 class='card-title-generic2'>Software as a Service</h5>
                    <p class='card-generic-text2'>
                      <span className='text-bold'>
                        Integration, customization and training
                      </span>
                      of digital solutions already built, focused on delivering
                      <span className='text-bold'>
                        {' '}
                        value to your business , with optimized costs and
                      </span>
                      in the short term
                      <span className='text-bold'> future growth </span>
                      <span className='text-bold'> planning. </span>
                      We look for platforms as services or solutions as services
                      <span className='text-bold'> (PaaS, SaaS).</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Servicios;
