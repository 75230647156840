import React from 'react';
import '../../../../assets/css/atoms/sections.css';

const Service = () => {
  return (
    <div className='container-fluid mt-5 pt-52 '>
      <p className='text-white pt-100 text-section-service pb-5'>
        Nuestros servicios
      </p>
      <div className='row container-service'>
        <div className='col-md-1 col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_a6607ae8164c47a290bed855bf35f129~mv2.gif'
            width='70'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-6 pl-2 col-service-1'>
          <h2 className='title-services'>Consultoría estratégica</h2>
          <p className='text-services'>
            Benefíciese de nuestra experiencia y descubra la mejor manera de
            gestionar sus datos, de forma sencilla y rentable. Cree una
            verdadera cultura basada en conocimientos para competir y ganar.
          </p>
        </div>
      </div>
      <div className='row mt-4 container-service'>
        <div className='col-md-2 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_f23dd96423844c08be4ce4f95bc65373~mv2.gif'
            width='70'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-6 pl-2 col-service-1'>
          <h2 className='title-services'>Integración e Ingeniería</h2>
          <p className='text-services'>
            Agregue, limpie, estructure y asegure sus datos para análisis.
          </p>
        </div>
      </div>
      <div className='row mt-5 container-service'>
        <div className='col-md-3 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_b763c1569c404f858bd27024bf457d15~mv2.gif'
            width='90'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>Almacenamiento</h2>
          <p className='text-services'>
            Almacene sus datos de forma centralizada en un almacén optimizado
            creado para análisis: la base para obtener información crítica más
            rápido.
          </p>
        </div>
      </div>
      <div className='row mt-6 mb-6 container-service'>
        <div className='col-md-4 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_0319620106f74214871945738a364a86~mv2.gif'
            width='100'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>Fuente única de la verdad</h2>
          <p className='text-services'>
            Datos precisos y confiables en los que puede confiar para tomar
            decisiones críticas.
          </p>
        </div>
      </div>
      <div className='row mt-5 container-service'>
        <div className='col-md-3 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_4296f783d732450c98445469109d143d~mv2.gif'
            width='100'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>Visualización</h2>
          <p className='text-services'>
            Cree hermosas experiencias de usuario que muestren de manera óptima
            el negocio e inspiren curiosidad para explorar intuitivamente y
            encontrar las respuestas que busca.
          </p>
        </div>
      </div>
      <div className='row mt-6 container-service'>
        <div className='col-md-2 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_c015c035d56b4a84910fd72d15a85638~mv2.gif'
            width='70'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-6 pl-2 col-service-1'>
          <h2 className='title-services'>Modernización de la nube</h2>
          <p className='text-services'>
            Utilice la mejor infraestructura en la nube para administrar sus
            datos de la manera más eficaz y rentable.
          </p>
        </div>
      </div>
      <div className='row container-service mt-6'>
        <div className='col-md-1 col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_c2868c98ae9949eda97ddcaf577bcf34~mv2.gif'
            width='70'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-6 pl-2 col-service-1'>
          <h2 className='title-services'>Automatización</h2>
          <p className='text-services'>
            Detenga los cuellos de botella manuales y minimice los requisitos de
            FTE. Automatice los flujos de trabajo para ahorrar tiempo, reducir
            costos y mejorar el rendimiento.
          </p>
        </div>
      </div>
      <div className='row mt-4 container-service'>
        <div className='col-md-2 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_ae96b0007633406f9039ffa798762bdf~mv2.gif'
            width='70'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-6 pl-2 col-service-1'>
          <h2 className='title-services'>Seguridad y Gobernanza</h2>
          <p className='text-services'>
            El más alto nivel de seguridad de datos es nuestro estándar. Nos
            aseguramos de que sus datos estén seguros y confiables, con solo
            acceso autorizado .
          </p>
        </div>
      </div>
      <div className='row mt-5 container-service'>
        <div className='col-md-3 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_f7723ffdd94d42359824f19d786b8fce~mv2.gif'
            width='90'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>Modelado</h2>
          <p className='text-services'>
            Organice y simplifique su estructura de datos y estandarice para
            lograr coherencia, usabilidad y escalabilidad.
          </p>
        </div>
      </div>
      <div className='row mt-6 mb-6 container-service'>
        <div className='col-md-4 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_4721e9ae1c8e46a0b70610da404c0f74~mv2.gif'
            width='100'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>Gestión de datos maestros (MDM)</h2>
          <p className='text-services'>
            Sepa qué significan realmente sus datos con definiciones de datos
            centrales uniformes, precisas y confiables .
          </p>
        </div>
      </div>
      <div className='row mt-5 container-service'>
        <div className='col-md-3 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_df8b0858f88c4b31b3fff01c60366b8f~mv2.gif'
            width='90'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>Ciencia de datos, ML/IA</h2>
          <p className='text-services'>
            El éxito fundamental que comienza con su primer caso de uso es un
            catalizador para aprovechar las soluciones analíticas de siguiente
            nivel que evolucionan y escalan.
          </p>
        </div>
      </div>
      <div className='row mt-4 container-service'>
        <div className='col-md-2 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_d476e9f94cc840aa80b300fad7f93f7c~mv2.gif'
            width='70'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-6 pl-2 col-service-1'>
          <h2 className='title-services'>Seguridad y Gobernanza</h2>
          <p className='text-services'>
            Escale elásticamente hacia arriba o hacia abajo según sea necesario.
            Podemos asociarnos con usted en un proyecto de tarifa fija o en
            retención como una extensión de su equipo. Es fácil trabajar con
            nosotros .
          </p>
        </div>
      </div>
      <div className='row container-service mt-6'>
        <div className='col-md-1 col-service'>
          <img
            src='https://static.wixstatic.com/media/ad9488_0b691ac6a7fc4cb68c67fb7885049bbe~mv2.gif'
            width='70'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-6 pl-2 col-service-1'>
          <h2 className='title-services'>Evaluaciones de software</h2>
          <p className='text-services'>
            Lo ayudamos a comprender las mejores tecnologías en toda la pila.
            Las ventas de software pueden ser confusas y nosotros lo hacemos
            fácil.
          </p>
        </div>
      </div>
      <div className='row mt-5 container-service'>
        <div className='col-md-2 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/a7b7b2_fb42d29b1f094ff3a01e007b4166075a~mv2.gif'
            width='70'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-6 pl-2 col-service-1'>
          <h2 className='title-services'>Documentación completa</h2>
          <p className='text-services'>
            Documentamos la arquitectura de nuestra solución, los modelos, los
            libros de jugadas, las hojas de ruta, los recursos y los
            procedimientos .
          </p>
        </div>
      </div>
      <div className='row mt-5 container-service'>
        <div className='col-md-3 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/a7b7b2_1c44629ef70e4e7f965d8f0ecfcd34be~mv2.gif'
            width='90'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>Responsabilidad Total</h2>
          <p className='text-services'>
            Nos hacemos cargo de todo el problema de los datos de principio a
            fin: integración de fuentes, almacenamiento y visualización.
          </p>
        </div>
      </div>
      <div className='row mt-6 mb-6 container-service'>
        <div className='col-md-4 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/a7b7b2_61d4da35fae146d99fda2219fb5b2113~mv2.gif'
            width='100'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>
            Transformación cultural, capacitación y habilitación
          </h2>
          <p className='text-services'>
            Entrenamos a su equipo para el éxito con una comunicación y
            capacitación excepcionales. Nuestro soporte inmersivo asegura que su
            equipo se sienta cómodo con el proceso y extasiado con los
            resultados .
          </p>
        </div>
      </div>
      <div className='row mt-5 container-service'>
        <div className='col-md-3 text-align-end text-align-center-m col-service'>
          <img
            src='https://static.wixstatic.com/media/a7b7b2_be9b1f5c5c7248f98c4e27fada6622e6~mv2.gif'
            width='90'
            alt='gifmedia'
          />
        </div>
        <div className='col-md-5 pl-2 col-service-1'>
          <h2 className='title-services'>​Optimización en curso</h2>
          <p className='text-services'>
            Una vez que completamos nuestro caso de uso fundamental,
            evolucionamos y escalamos para convertir a su organización en un
            verdadero líder de datos.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Service;
