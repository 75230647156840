import React, { useState, useEffect } from 'react';

const First = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);
  return (
    <>
      {language === 'Spanish' ? (
        <div class='background-home' id='content-sliders'>
          <div class='background-content'>
            <div class='animated-title'>
              <div class='text-top'>
                <div>
                  <span className='title-first'>BPO & TECHNOLOGY </span>
                  <span className='text-first'>
                    Soluciones y servicios digitales para empresas y startups
                  </span>
                </div>
              </div>
              <div class='text-bottom text-second'>
                <div>
                  Proporcionamos soluciones y servicios tecnológicos
                  especializados en la nube, productos y equipos de alto
                  desempeño para crecer y operar negocios digitales.
                </div>
              </div>
            </div>
          </div>
          <div class='waves'>
            <div class='wave circulo a'></div>
            <div class='wave circulo b'></div>
            <div class='wave circulo c'></div>
          </div>
        </div>
      ) : (
        <div class='background-home' id='content-sliders'>
          <div class='background-content'>
            <div class='animated-title'>
              <div class='text-top'>
                <div>
                  <span className='title-first'>BPO & TECHNOLOGY </span>
                  <span className='text-first'>
                    Solutions and digital services for enterprises and startups
                  </span>
                </div>
              </div>
              <div class='text-bottom text-second'>
                <div>
                  We provide specialized cloud technology solutions and
                  services, products and high-performance equipment to grow and
                  operate digital businesses.
                </div>
              </div>
            </div>
          </div>
          <div class='waves'>
            <div class='wave circulo a'></div>
            <div class='wave circulo b'></div>
            <div class='wave circulo c'></div>
          </div>
        </div>
      )}
    </>
  );
};

export default First;
