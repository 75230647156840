import React from 'react';
import Form from '../components/commons/sections/contact/form';
import Layout from '../components/layout/layout';
const Contact = () => {
  return (
    <Layout>
      <div className='bg-black'>
        <Form />
      </div>
    </Layout>
  );
};

export default Contact;
