import React, { useState, useEffect } from 'react';
import Button from '../../../atoms/button';
import Titleh2 from '../../../atoms/title/titleh2';
import { savePersonName } from '../contact/api';
import contacto1 from '../../../../assets/img/pages/email.png';

const Contacto = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');

  const [contact, setContact] = useState(true);
  const [personaNombre, setPersonaNombre] = useState(null);
  const [personaApellido, setPersonaApellido] = useState(null);
  const [personaEmail, setPersonaEmail] = useState(null);
  const [personaTelefono, setPersonaTelefono] = useState(null);
  const [personaCompania, setPersonaCompania] = useState(null);
  const [personaPosicion, setPersonaPosicion] = useState(null);
  const [personaMensaje, setPersonaMensaje] = useState(null);
  const [personaTerminos, setTerminos] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);

  const savePerson = () => {
    if (personaEmail === null) {
      if (language === 'Spanish') {
        setError('Por favor ingrese el Email');
      } else {
        setError('Please enter the Email');
      }
    } else if (personaEmail.includes('@') && personaEmail.includes('.')) {
      setError('');
      if (!personaTerminos) {
          setError('Por favor, acepta los términos y condiciones');
          return;
      }
      

      if (personaTerminos) {
        savePersonName(
          personaNombre,
          personaApellido,
          personaEmail,
          personaTelefono,
          personaCompania,
          personaPosicion,
          personaMensaje,
          personaTerminos
        );

        let inputNombre = document.getElementById(`nombre`);
        inputNombre.value = '';

        let inputApellido = document.getElementById(`apellido`);
        inputApellido.value = '';

        let inputEmail = document.getElementById(`email`);
        inputEmail.value = '';

        let inputTelefono = document.getElementById(`telefono`);
        inputTelefono.value = '';

        let inputCompania = document.getElementById(`compania`);
        inputCompania.value = '';

        let inputPosition = document.getElementById(`position`);
        inputPosition.value = '';

        let inputMensaje = document.getElementById(`mensaje`);
        inputMensaje.value = '';

        setPersonaApellido(null);
        setPersonaEmail(null);
        setPersonaMensaje(null);
        setPersonaPosicion(null);
        setPersonaCompania(null);
        setPersonaNombre(null);
        setTerminos(false);
        setContact(false);
      }

    
    } else {
      setError('Email incorrecto');
      if (language === 'Spanish') {
        setError('Email incorrecto');
      } else {
        setError('Wrong email');
      }
    }
  };

  return (
    <>
      {language === 'Spanish' ? (
        contact ? (
          <>
            <div
              id='contacto'
              className='animate__fadeIn animate__bounce animate__delay-2s'>
              <div className='row pr-0 pl-0 mr-0 ml-0 mt-6 mtm-25'>
                <div className='col-lg-6 p-90 mt-77 text-align-center mtm-0'>
                  <h1 className='text-white title-contacto'>CONTÁCTENOS</h1>
                  <p className='text-white text-align-center'>
                    Complete este formulario si desea ser que nos comuniquemos{' '}
                    <br />
                    con usted{' '}
                    <a
                      href='mailto:contacto@bpotecnologia.com'
                      className='pink'>
                      contacto@bpotecnologia.com
                    </a>
                  </p>

                  <div className='row form-contact col-md-12 mtm-0'>
                    <div className='col-sm-5'>
                      <input
                        type='text'
                        onChange={(e) => setPersonaNombre(e.target.value)}
                        placeholder='Nombre'
                        className='input-contact'
                        id='nombre'
                        name='nombre'
                      />
                    </div>
                    <div className='col-sm-5'>
                      <input
                        type='text'
                        onChange={(e) => setPersonaApellido(e.target.value)}
                        placeholder='Apellido'
                        className='input-contact'
                        id='apellido'
                      />
                    </div>

                    <div className='col-sm-5'>
                      <input
                        type='email'
                        onChange={(e) => setPersonaEmail(e.target.value)}
                        placeholder='Email'
                        className='input-contact'
                        id='email'
                      />
                    </div>
                    <div className='col-sm-5'>
                      <input
                        type='phone'
                        onChange={(e) => setPersonaTelefono(e.target.value)}
                        placeholder='Telefono'
                        className='input-contact'
                        id='telefono'
                      />
                    </div>
                    <div className='col-sm-5'>
                      <input
                        type='text'
                        onChange={(e) => setPersonaCompania(e.target.value)}
                        placeholder='Compañia'
                        className='input-contact'
                        id='compania'
                      />
                    </div>
                    <div className='col-sm-5'>
                      <input
                        type='text'
                        onChange={(e) => setPersonaPosicion(e.target.value)}
                        placeholder='Puesto'
                        className='input-contact'
                        id='position'
                      />
                    </div>
                    <div className='col-sm-9 section-text-area'>
                      <textarea
                        type='text'
                        onChange={(e) => setPersonaMensaje(e.target.value)}
                        placeholder='Mensaje'
                        className='text-area-contact'
                        id='mensaje'
                      />
                    </div>
                    <div className='col-sm-8'>
                      <label className='label-checkbox-termino-contact'>
                        <input
                          type='checkbox'
                          onChange={(e) => setTerminos(e.target.checked)}
                          className='input-checkbox-terminos'
                          id='terminos'
                          required
                        />
                        Al hacer click en Enviar , acepta nuestra{' '}
                        <a
                          href='/aviso-de-privacidad'
                          target='_blank'
                          rel='noopener noreferrer'
                          className='content-section-link-contacto'>
                          Política de Privacidad
                        </a>{' '}
                        descrita en esta página.
                      </label>
                    </div>
                    {error && <p className='error'>{error}</p>}
                    <div className='col-sm-12'>
                      <button onClick={savePerson} className='button-contact'>
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 section-img-contact'>
                  <img
                    src={contacto1}
                    alt='img-contact'
                    className='responsive img-contact'
                  />
                </div>
                <div className='background-black mt-4 mb-5'>
                  <div className='text-center mt-6 container-fluid mtm-25'>
                    <Titleh2
                      text=' Abramos la discusión de tus ideas'
                      className='title2 mb-5 '
                    />
                    <Button text='TE QUEREMOS ESCUCHAR' />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div id='contacto'>
            <div className='row pr-0 pl-0 mr-0 ml-0 mt-6 mtm-25 animate__animated animate__fadeIn'>
              <div className='col-lg-6 p-90 mt-77 text-align-center mtm-0'>
                <h1 className='text-white title-contacto'>Gracias</h1>
                <p className='text-white text-align-center text-second-home-contact'>
                  Nos pondremos en contacto con usted en breve.
                </p>
              </div>
              <div className='col-lg-6'>
                <img
                  src={contacto1}
                  alt='img-contact'
                  className='responsive img-contact'
                />
              </div>
            </div>
          </div>
        )
      ) : contact ? (
        <>
          <div
            id='contacto'
            className='animate__fadeIn animate__bounce animate__delay-2s'>
            <div className='row pr-0 pl-0 mr-0 ml-0 mt-6 mtm-25'>
              <div className='col-lg-6 p-90 mt-77 text-align-center mtm-0'>
                <h1 className='text-white title-contacto'>CONTACT US</h1>
                <p className='text-white text-align-center'>
                  Complete this form if you wish to be contacted by us
                  <br />
                  con usted{' '}
                  <a href='mailto:contacto@bpotecnologia.com' className='pink'>
                    contacto@bpotecnologia.com
                  </a>
                </p>

                <div className='row form-contact col-md-12 mtm-0'>
                  <div className='col-sm-5'>
                    <input
                      type='text'
                      onChange={(e) => setPersonaNombre(e.target.value)}
                      placeholder='First name'
                      className='input-contact'
                      id='nombre'
                      name='nombre'
                    />
                  </div>
                  <div className='col-sm-5'>
                    <input
                      type='text'
                      onChange={(e) => setPersonaApellido(e.target.value)}
                      placeholder='Last name'
                      className='input-contact'
                      id='apellido'
                    />
                  </div>

                  <div className='col-sm-5'>
                    <input
                      type='email'
                      onChange={(e) => setPersonaEmail(e.target.value)}
                      placeholder='Email'
                      className='input-contact'
                      id='email'
                    />
                  </div>
                  <div className='col-sm-5'>
                    <input
                      type='phone'
                      onChange={(e) => setPersonaTelefono(e.target.value)}
                      placeholder='Phone'
                      className='input-contact'
                      id='telefono'
                    />
                  </div>
                  <div className='col-sm-5'>
                    <input
                      type='text'
                      onChange={(e) => setPersonaCompania(e.target.value)}
                      placeholder='Company'
                      className='input-contact'
                      id='compania'
                    />
                  </div>
                  <div className='col-sm-5'>
                    <input
                      type='text'
                      onChange={(e) => setPersonaPosicion(e.target.value)}
                      placeholder='Position'
                      className='input-contact'
                      id='position'
                    />
                  </div>
                  <div className='col-sm-9 section-text-area'>
                    <textarea
                      type='text'
                      onChange={(e) => setPersonaMensaje(e.target.value)}
                      placeholder='Message'
                      className='text-area-contact'
                      id='mensaje'
                    />
                  </div>
                  <div className='col-sm-8'>
                    <label className='label-checkbox-termino-contact'>
                      <input
                        type='checkbox'
                        onChange={(e) => setTerminos(e.target.checked)}
                        className='input-checkbox-terminos'
                        id='terminos'
                        required
                      />
                      By clicking Submit, you agree to our{' '}
                      <a
                        href='/aviso-de-privacidad'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='content-section-link-contacto'>
                        Privacy Policy
                      </a>{' '}
                      described on this page.
                    </label>
                  </div>
                  {error && <p className='error'>{error}</p>}
                  <div className='col-sm-12'>
                    <button onClick={savePerson} className='button-contact'>
                      Send
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 section-img-contact'>
                <img
                  src={contacto1}
                  alt='img-contact'
                  className='responsive img-contact'
                />
              </div>
              <div className='background-black mt-4 mb-5'>
                <div className='text-center mt-6 container-fluid mtm-25'>
                  <Titleh2
                    text='Let is open the discussion of your ideas'
                    className='title2 mb-5 '
                  />
                  <Button text='WE WANT TO HEAR FROM YOU' />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div id='contacto'>
          <div className='row pr-0 pl-0 mr-0 ml-0 mt-6 mtm-25 animate__animated animate__fadeIn'>
            <div className='col-lg-6 p-90 mt-77 text-align-center mtm-0'>
              <h1 className='text-white title-contacto'>Thanks</h1>
              <p className='text-white text-align-center text-second-home-contact'>
                We will contact you shortly.
              </p>
            </div>
            <div className='col-lg-6'>
              <img
                src={contacto1}
                alt='img-contact'
                className='responsive img-contact'
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contacto;
