import React from 'react';
import '../../../../assets/css/atoms/sections.css';
import Button from '../../../atoms/button';

const Contact = () => {
  return (
    <div className='text-align-webkit-center'>
      <div className='background-section-contact pb-2'>
        <div className='section-contact'>
          <p className='mt-6 text-white  text-section-contact'>
            Hagamos <br /> equipo
          </p>
          <Button
            divclassName='text-left'
            href='/contacto'
            className='button-section-contact'
            text='Contáctenos'
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
