import React from 'react';
import logonegro from '../../../assets/img/logo2.png';

export const Logo = ({ className = 'logo' }) => {
  return (
    <>
      <img src={logonegro} alt='BPO' className={className} />
    </>
  );
};
