import React from 'react';
import Contens from '../components/commons/sections/solutions/contens';
import Layout from '../components/layout/layout';
const Solutions = () => {
  return (
    <Layout>
      <div className='bg-white'>
        <Contens />
      </div>
    </Layout>
  );
};

export default Solutions;
