import React, { useState, useEffect } from 'react';

const Avisodeprivacidad = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);
  return (
    <>
      {language === 'Spanish' ? (
        <div className='container-fluid mt-5 pt-52 pb-30'>
          <h1 className='pt-100 title-section-aviso-privacidad pb-5'>
            Aviso de Privacidad (Política de privacidad de datos)
          </h1>
          <p className='content-section-aviso-privacidad'>
            De conformidad con lo dispuesto por la Ley Federal de Protección de
            Datos Personales en Posesión de los Particulares, su Reglamento y
            diversa normativa vigente y relacionada (en adelante, “Legislación
            de Datos Personales”), se emite el presente Aviso de Privacidad.
          </p>
          <p className='content-title-aviso-privacidad'>
            1. Identidad y domicilio de los Responsables.
          </p>
          <p className='content-section-aviso-privacidad'>
            BPO & TECHNOLOGY (en adelante, "BPO") obtienen, usan y almacenan (en
            adelante, “Tratamiento”) los datos personales de usted como titular
            de los mismos (en adelante, “Usted”).. BPO cuenta con domicilio para
            efectos del presente Aviso de Privacidad en Calle Manuel Carreón y
            Rubio MZ 130 Lt 22 Colonia Santa Martha Acatitla, alcaldía
            Iztapalapa, Ciudad de México c.P 09510.
          </p>
          <p className='content-title-aviso-privacidad'>
            2. Tratamiento de los Datos Personales.
          </p>
          <p className='content-section-aviso-privacidad'>
            BPO será responsable de los datos personales que recabe a través de
            cualquier canal digital incluyendo redes sociales, correos
            electrónicos, su página de internet{' '}
            <a className='content-section-link-privacidad' href='/'>
              www.bpotecnologia.com
            </a>
            , portales y/o Apps.BPO también podrá recabar sus datos personales a
            través de cualquier otro contacto con Usted, de terceros y de otras
            fuentes permitidas por ley, tales como empresas de reclutamiento,,
            terceros, autoridades y sociedades de información.
          </p>
          <p className='content-section-aviso-privacidad'>
            En caso de que haya proporcionado datos de contacto de terceros,
            Usted garantiza que cuenta con su autorización para proporcionar
            dichos datos, incluyendo en su caso los datos personales aplicables,
            y es responsable de comunicar a dichas personas sobre los términos y
            razones por las cuales los contactaremos, y, en su caso, el
            Tratamiento de sus datos personales conforme al presente Aviso de
            Privacidad, así como los medios para conocer el contenido íntegro
            del mismo. Adicionalmente, mediante su consentimiento al presente
            Aviso de Privacidad, Usted ratifica su autorización para BPO que lo
            contacte a Usted y/o a dichas personas.
          </p>
          <p className='content-title-aviso-privacidad'>
            3. Datos personales Tratados por los Responsables de BPO.
          </p>
          <p className='content-section-aviso-privacidad'>
            Para las finalidades indicadas en el presente Aviso de Privacidad,
            trataremos los datos personales para las categorías siguientes:
            <ul className='content-section-ul-aviso-privacidad'>
              <li>
                <span>Datos de identificación y contacto</span>
              </li>
              <li>
                <span>Datos financieros y patrimoniales</span>
              </li>
              <li>
                <span>Datos laborales</span>
              </li>
              <li>
                <span>
                  Interacciones con perfiles y comportamientos en las Redes
                  Sociales, Portales o Sitios y Apps
                </span>
              </li>
              <li>
                <span>Datos de localización</span>
              </li>
              <li>
                <span>
                  Llamadas y conversaciones en nuestros centros de atención
                  (call centers o chat en línea) o mediante correo electrónico
                </span>
              </li>
              <li>
                <span>
                  Datos de la lista de contactos del Titular (nombre y número
                  celular)
                </span>
              </li>
            </ul>
          </p>
          <p className='content-title-aviso-privacidad'>
            4. Finalidades del Tratamiento.
          </p>
          <p className='content-section-aviso-privacidad'>
            Trataremos los datos personales para finalidades primarias
            siguientes:
            <ul className='content-section-ul-second-aviso-privacidad'>
              <li>
                <span className='bold'>1.</span> Comercialización y operación
                derivada de nuestros productos y/o servicios, incluyendo
                reclutamiento, capacitación y contratación de personal.
              </li>
              <li>
                <span className='bold'>2.</span> Exhibir y/o comercializar
                productos y/o servicios personalizados en nuestros Portales o
                Sitios y Apps conforme a los intereses y necesidades
                particulares del Usuario.
              </li>
              <li>
                <span className='bold'> 3.</span> Crear, ordenar, catalogar,
                clasificar, dividir, separar y almacenar datos y expedientes de
                los Usuarios.
              </li>
              <li>
                <span className='bold'>4.</span> Crear y administrar la cuenta
                de los Usuarios en nuestros Sitios y Apps y verificar, validar y
                confirmar la identidad de los Usuarios y la información
                proporcionada por los mismos, internamente o a través de
                cualquier autoridad o tercero.
              </li>
              <li>
                <span className='bold'>5.</span> Proporcionar al Usuario
                información, comunicaciones y notificaciones sobre nuestros
                Portales o Sitios y Apps, de nuestros productos y/o servicios y
                los productos y servicios de terceros exhibidos en nuestros
                Portales o Sitios y Apps, y, los términos y condiciones
                aplicables a los mismos.
              </li>
              <li>
                <span className='bold'>6. </span>Administrar y procesar pagos,
                operaciones y transacciones de los Usuarios que utilicen
                nuestros Portales o Sitios y Apps.
              </li>
              <li>
                <span className='bold'>7. </span> Realizar estrategias de
                mercado y elaborar segmentación de mercados.
              </li>
              <li>
                <span className='bold'>8. </span> Realizar encuestas de
                satisfacción de nuestros productos y/o servicios.
              </li>
              <li>
                <span className='bold'>9.</span> Ofrecer ofertas, promociones y
                beneficios de programas de lealtad y beneficios de BPO y/o
                terceros.
              </li>
              <li>
                <span className='bold'>10. </span>Proporcionar asesoría,
                aclaraciones, atención de quejas, servicio al cliente y servicio
                postventa, así como monitorear la calidad de los mismos mediante
                la grabación de llamadas y almacenamiento de las conversaciones
                correspondiente.
              </li>
              <li>
                <span className='bold'>11.</span> Tener acceso a centrales de
                riesgo o burós de créditos para conocer su capacidad y
                comportamiento de pago, así como los estados financieros de los
                Usuarios.
              </li>
              <li>
                <span className='bold'> 12.</span> Cumplir con cualesquiera
                disposiciones y obligaciones legales, nacionales o
                internacionales, aplicables a BPO, así como para gestionar
                cualquier proceso judicial o tramitar y responder cualesquier
                requerimientos o solicitudes de las autoridades.
              </li>
              <li>
                <span className='bold'>13.</span> Realizar actividades de
                cobranza.
              </li>
            </ul>
          </p>
          <p className='content-section-aviso-privacidad'>
            Trataremos los datos personales para finalidades secundarias
            siguientes:
            <ul className='content-section-ul-second-aviso-privacidad'>
              <li>
                <span className='bold'>1.</span> Mercadotecnia, publicidad,
                promoción, prospección comercial, procesos de referencias, la
                elaboración, valoración y análisis de perfiles de los Usuarios
                para el desarrollo y ofrecimiento de nuevos productos y
                servicios, realización de encuestas ajenas a nuestros productos
                y/o servicios, fines analíticos y estadísticos, todos ellos
                relacionados con los productos y/o servicios de BPO, aliados y
                otros terceros.
              </li>
              <li>
                <span className='bold'>2.</span> Contacto y envío de información
                de novedades, noticias, ofertas de BPO o terceros, boletines,
                foros de educación, publicidad o marketing, ventas a distancia,
                haciendo uso de medios tales como, pero sin limitar, correo
                electrónico, notificaciones PUSH, mensajes de texto (SMS),
                mensajería instantánea, llamadas y ventanas de diálogo
                (pop-ups).
              </li>
            </ul>
            En caso de que Usted no desee que sus datos personales sean tratados
            para estas finalidades secundarias, Usted puede: enviar desde este
            momento un correo electrónico a <a
              className='content-section-link-privacidad'
              href='mailto:contacto@bpotecnologia.com'
              target='_blank'
              rel='noopener noreferrer'>
              contacto@bpotecnologia.com
            </a>
            .
          </p>
          <p className='content-title-aviso-privacidad'>
            5. Opciones y medios que le ofrecemos para limitar el uso o
            divulgación de sus datos personales.
          </p>
          <p className='content-section-aviso-privacidad'>
            En todos aquellos casos legalmente procedentes, Usted podrá limitar
            el uso o divulgación de sus datos personales, presentando una
            solicitud por medio del envío de un correo a electrónico a{' '}
            <a
              className='content-section-link-privacidad'
              href='mailto:contacto@bpotecnologia.com'
              target='_blank'
              rel='noopener noreferrer'>
              contacto@bpotecnologia.com
            </a>
          </p>
          <p className='content-title-aviso-privacidad'>
            6. Transferencia de datos personales.
          </p>
          <p className='content-section-aviso-privacidad'>
            Sus datos personales podrán ser transferidos dentro del territorio
            nacional o hacia el extranjero (en cumplimiento con la regulación
            aplicable), conforme a lo siguiente:
            <ul className='content-section-ul-second-aviso-privacidad'>
              <li>
                <span className='bold'>1.</span> A autoridades, como puede ser
                de forma enunciativa más no limitativa el Instituto Nacional
                Electoral (en adelante, “INE”), organismos o entidades
                gubernamentales, en cumplimiento o con relación a las
                obligaciones contempladas en la legislación aplicable a BPO, sus
                proveedores, clientes o asociados de negocio.
              </li>
              <li>
                <span className='bold'>2.</span> A autoridades, como puede ser
                de forma enunciativa más no limitativa el INE, organismos o
                entidades gubernamentales, cuando la transferencia sea requerida
                oficialmente y/o necesaria para el reconocimiento, ejercicio o
                defensa de un derecho de a BPO, sus proveedores, clientes o
                asociados de negocio. en un proceso judicial.
              </li>
              <li>
                <span className='bold'> 3.</span> A compañías controladoras y/o
                afiliadas y/o subsidiarias y/o empresas relacionadas o
                pertenecientes a BPO con finalidades de mercadotecnia,
                publicidad, promoción, prospección comercial, de procesos de
                referencias, de elaboración, valoración y análisis de perfiles
                de los Usuarios, de resguardo centralizado de la información, de
                fines analíticos y estadísticos y de registro histórico de
                usuarios y de la realización de encuestas.
              </li>
              <li>
                <span className='bold'> 4.</span> A terceros adquirentes,
                derivado de una reestructura corporativa de BPO, incluyendo, la
                fusión, consolidación, venta, liquidación o transferencia de
                activos, cuando la transferencia sea necesaria para el
                mantenimiento o cumplimiento de una relación entre BPO y los
                Terceros.
              </li>
              <li>
                <span className='bold'> 5.</span> A terceros que exhiben y
                comercializan productos y/o servicios o aceptan realizar la
                gestión de un encargo a través de los Portales o Sitios y de las
                Apps, así como a prestadores de servicios y aliados comerciales
                incluyendo instituciones financieras, cuando la transferencia
                sea necesaria para el mantenimiento o para administrar,
                desarrollar, dar mantenimiento, personalizar y mejorar nuestros
                Portales o Sitios y Apps.
              </li>
              <li>
                <span className='bold'> 6.</span> Los demás supuestos
                establecidos en la Legislación de Datos Personales, que no
                requieren de su consentimiento. Si no manifiesta su negativa
                para dicha transferencia, lo cual puede realizar desde este
                momento enviando un correo al correo electrónico
                contacto@bpotecnologia.com, de lo contrario entenderemos que nos
                ha otorgado su consentimiento.
              </li>
            </ul>
          </p>
          <p className='content-title-aviso-privacidad'>
            7. Medios para el ejercicio de sus derechos.
          </p>
          <p className='content-section-aviso-privacidad'>
            En todos aquellos casos legalmente procedentes, Usted podrá, en todo
            momento y, ejercer ante BPO los siguientes derechos referentes a sus
            datos personales:
            <ul className='content-section-ul-aviso-privacidad'>
              <li>
                <span>
                  Derechos de Acceso, Rectificación, Cancelación y Oposición.
                </span>
              </li>
              <li>
                <span>
                  Revocar el consentimiento otorgado aBPO para el uso de sus
                  datos personales.
                </span>
              </li>
              <li>
                <span>
                  Limitar el uso o divulgación de sus datos personales.
                </span>
              </li>
              <li>
                <span>
                  Manifestar su negativa para el tratamiento de sus datos
                  personales con respecto a las finalidades secundarias antes
                  mencionadas.
                </span>
              </li>
            </ul>
            Para el ejercicio de dichos derechos referentes a sus datos
            personales deberá presentar la solicitud correspondiente, mediante
            el debido llenado el formulario electrónico que pondremos a su
            disposición, después de recibir su solicitud al correo electrónico{' '}
            <a
              className='content-section-link-privacidad'
              href='mailto:contacto@bpotecnologia.com'
              target='_blank'
              rel='noopener noreferrer'>
              contacto@bpotecnologia.com
            </a>{' '}
            y después de llenar el formulario debe enviarlo al correo
            electrónico{' '}
            <a
              className='content-section-link-privacidad'
              href='mailto:contacto@bpotecnologia.com'
              target='_blank'
              rel='noopener noreferrer'>
              contacto@bpotecnologia.com
            </a>{' '}
            , con firma autógrafa al calce del mismo, estableciendo como asunto
            “Ejercicio de derechos sobre datos personales”, y, acompañado de lo
            siguiente:
            <ul className='content-section-ul-aviso-privacidad'>
              <li>
                <span>
                  Copia de los documentos que acrediten su identidad
                  (identificación oficial vigente con fotografía) o, en su caso,
                  la representación legal, conforme a los términos establecidos
                  en la Legislación de Datos Personales.
                </span>
              </li>
              <li>
                <span>
                  Cualquier otro elemento o documento que facilite la
                  localización de sus datos personales.
                </span>
              </li>
            </ul>
            Le daremos trámite a su solicitud para el ejercicio de sus derechos
            referentes a datos personales en un plazo no mayor a 20 (veinte)
            días hábiles contados a partir de su recepción. BPO podrá ampliar
            este plazo hasta por 20 (veinte) días hábiles adicionales cuando el
            caso lo amerite, previa notificación. En caso de que su solicitud
            resulte procedente, la misma se hará efectiva dentro de los 15
            (quince) días hábiles siguientes al envío de la respuesta por parte
            de BPO.
          </p>
          <p className='content-section-aviso-privacidad'>
            BPO desea mantener actualizados sus datos personales en todo
            momento. Por lo anterior, solicitamos su cooperación para
            comunicarnos oportunamente cualquier cambio que debamos tener en
            cuenta, para incorporarlo a nuestras bases de datos.
          </p>
          <p className='content-section-aviso-privacidad'>
            Usted garantiza y responde, en cualquier caso, de la veracidad,
            exactitud, vigencia y autenticidad de sus datos personales o los de
            cualquier tercero bajo su custodia o representación legal, en el
            entendido de que BPO cumplirá con lo establecido en la legislación
            aplicable con relación a los datos de dichos terceros recibidos por
            BPO.
          </p>
          <p className='content-title-aviso-privacidad'>
            8. Medios que permiten recabar datos personales de manera
            automática.
          </p>
          <p className='content-section-aviso-privacidad'>
            Le informamos que los Portales o Sitios, aplicaciones o Apps,
            servicios de call center contratados por BPO o servicios de Call
            Center de BPO y por medio de los canales digitales incluyendo Redes
            Sociales hacen uso de cookies y otras tecnologías, a través de las
            cuales es posible monitorear automáticamente su comportamiento,
            brindarle nuestros productos y/o servicios y otorgarle una
            experiencia personalizada durante el uso de los mismos, así como
            ofrecerle nuevos productos y servicios basados en sus preferencias.
            Los datos personales que se recaban a través de estas tecnologías
            son: dirección IP, sitios web y secciones de los mismos que Usted
            visita desde los Sitios, antes de los Sitios o en páginas
            relacionadas con los Sitios y las Apps, características de
            navegador, características de dispositivos, sistema operativo,
            preferencias de idiomas, URL a las que se hace referencia,
            información sobre conductas y acciones realizadas en los Sitios y
            las Apps, información sobre conductas y acciones realizadas mientras
            las Apps está abierta, fechas y horas de las visitas a los Portales
            o Sitios o acceso las Apps, API ́s de terceros y secciones o
            contenido consultadas en los mismos y datos de ubicación y
            localización del Usuario mientras las Apps estén abiertas.
          </p>

          <p className='content-title-aviso-privacidad'>
            9. Cambios al Aviso de Privacidad.
          </p>
          <p className='content-section-aviso-privacidad'>
            El presente Aviso de Privacidad puede sufrir modificaciones, cambios
            o actualizaciones derivadas de nuevos requerimientos legales, de
            nuestras propias necesidades por los productos y/o servicios que
            ofrecemos, de nuestras prácticas, programas o políticas de
            privacidad, de cambios en nuestro modelo de negocio o por otras
            causas. Nos comprometemos a mantenerlo informado sobre los cambios
            que pueda sufrir el presente Aviso de Privacidad, a través de
            nuestros Portales o Sitios, de las Apps, o cualquier canal digital
            que nos permita la tecnología y en la sección correspondiente al
            Aviso de Privacidad de nuestra página{' '}
            <a className='content-section-link-privacidad' href='/'>
              www.bpotecnologia.com
            </a>
            .
          </p>
        </div>
      ) : (
        <>
          <div className='container-fluid mt-5 pt-52 pb-30'>
            <h1 className='pt-100 title-section-aviso-privacidad pb-5'>
              Privacy Notice (Data Privacy Policy)
            </h1>
            <p className='content-section-aviso-privacidad'>
              In accordance with the provisions of the Federal Protection Law of
              Personal Data in Possession of Individuals, its Regulation and
              various current and related regulations (hereinafter, "Personal
              Data Legislation"), this Notice of Privacy.
            </p>
            <p className='content-title-aviso-privacidad'>
              1. Identity and address of the Responsible.
            </p>
            <p className='content-section-aviso-privacidad'>
              BPO & TECHNOLOGY (hereinafter, "BPO") obtain, use and store
              (hereinafter, "Treatment") your personal data such as owner of the
              same (hereinafter, "You"). BPO has address for the purposes of
              this Privacy Notice in Calle Manuel Carreón y Rubio MZ 130 Lt 22
              Colonia Santa Martha Acatitla, Iztapalapa mayor's office, Mexico
              City c.P 09510.
            </p>
            <p className='content-title-aviso-privacidad'>
              2. Treatment of Personal Data.
            </p>
            <p className='content-section-aviso-privacidad'>
              BPO will be responsible for the personal data it collects through
              of any digital channel including social networks, emails emails,
              your website{' '}
              <a className='content-section-link-privacidad' href='/'>
                www.bpotecnologia.com
              </a>
              , portals and/or Apps.BPO may also collect your personal data
              through any other contact with you, from third parties and from
              other sources permitted by law, such as insurance companies
              recruitment, third parties, authorities and information societies.
            </p>
            <p className='content-section-aviso-privacidad'>
              In case you have provided third party contact details, You warrant
              that you are authorized to provide said data, including where
              appropriate personal data applicable, and is responsible for
              communicating to such persons about the terms and reasons why we
              will contact you, and, in your case, the Processing of your
              personal data in accordance with this Privacy Notice, as well as
              the means to know the content full thereof. Additionally, through
              your consent to this Privacy Notice, you ratify your authorization
              to BPO that contacts you and/or said persons.
            </p>
            <p className='content-title-aviso-privacidad'>
              3. Personal Data Processed by the BPO Managers.
            </p>
            <p className='content-section-aviso-privacidad'>
              For the purposes indicated in this Privacy Notice, We will process
              personal data for the following categories:
              <ul className='content-section-ul-aviso-privacidad'>
                <li>
                  <span>Identification and contact information</span>
                </li>
                <li>
                  <span>Financial and asset data</span>
                </li>
                <li>
                  <span>Work data</span>
                </li>
                <li>
                  <span>
                    Interactions with profiles and behaviors in the Networks
                    Social, Portals or Sites and Apps
                  </span>
                </li>
                <li>
                  <span>Location data</span>
                </li>
                <li>
                  <span>
                    Calls and conversations in our service centers (call centers
                    or online chat) or via email
                  </span>
                </li>
                <li>
                  <span>
                    Data from the Holder's contact list (name and number cell
                    phone)
                  </span>
                </li>
              </ul>
            </p>
            <p className='content-title-aviso-privacidad'>
              4. Purposes of the Treatment.
            </p>
            <p className='content-section-aviso-privacidad'>
              We will process personal data for primary purposes following:
              <ul className='content-section-ul-second-aviso-privacidad'>
                <li>
                  <span className='bold'>1.</span> Marketing and operation
                  derived from our products and/or services, including
                  recruitment, training and hiring of personnel.
                </li>
                <li>
                  <span className='bold'>2.</span> Exhibit and/or commercialize
                  personalized products and/or services on our Portals or Sites
                  and Apps according to interests and needs User's particulars.
                </li>
                <li>
                  <span className='bold'> 3.</span> Create, sort, catalog,
                  classify, divide, separate and store data and records of the
                  users.
                </li>
                <li>
                  <span className='bold'>4.</span> Create and manage account of
                  Users on our Sites and Apps and verify, validate and confirm
                  the identity of the Users and the information provided by
                  them, internally or through any authority or third party.
                </li>
                <li>
                  <span className='bold'>5.</span> Provide to User information,
                  communications and notifications about our Portals or Sites
                  and Apps, of our products and/or services and third-party
                  products and services displayed on our Portals or Sites and
                  Apps, and the terms and conditions applicable to them.
                </li>
                <li>
                  <span className='bold'>6. </span>Manage and process payments,
                  operations and transactions of Users who use our Portals or
                  Sites and Apps.
                </li>
                <li>
                  <span className='bold'>7. </span> Carry out strategies of
                  market and develop market segmentation.
                </li>
                <li>
                  <span className='bold'>8. </span> Conduct surveys of
                  satisfaction with our products and/or services.
                </li>
                <li>
                  <span className='bold'>9.</span> Offer offers, promotions and
                  benefits of loyalty programs and BPO benefits and/or third
                  parties.
                </li>
                <li>
                  <span className='bold'>10. </span>Provide advice,
                  clarifications, attention to complaints, customer service and
                  after-sales service, as well as monitoring the quality of the
                  themselves by recording calls and storing the corresponding
                  conversations.
                </li>
                <li>
                  <span className='bold'>11.</span> Have access to call centers
                  risk or credit bureaus to know their capacity and payment
                  behavior, as well as the financial statements of the users.
                </li>
                <li>
                  <span className='bold'> 12.</span> Comply with any provisions
                  and legal obligations, national or international, applicable
                  to BPO, as well as to manage any judicial process or process
                  and respond to any requirements or requests from the
                  authorities.
                </li>
                <li>
                  <span className='bold'>13.</span> Carry out training
                  activities collection.
                </li>
              </ul>
            </p>
            <p className='content-section-aviso-privacidad'>
              We will process personal data for secondary purposes following:
              <ul className='content-section-ul-second-aviso-privacidad'>
                <li>
                  <span className='bold'>1.</span> Marketing, advertising,
                  promotion, commercial prospecting, referral processes, the
                  preparation, assessment and analysis of User profiles for the
                  development and offering of new products and services,
                  carrying out surveys outside our products and/or services,
                  analytical and statistical purposes, all of them related to
                  the products and/or services of BPO, allies and other third
                  parties.
                </li>
                <li>
                  <span className='bold'>2.</span> Contact and sending
                  information on news, news, offers from BPO or third parties,
                  newsletters, education forums, advertising or marketing, sales
                  at a distance, making use of means such as, but without limit,
                  email, push notifications, text messages text (SMS), instant
                  messaging, calls and windows of dialog (pop-ups).
                </li>
              </ul>
              In case you do not want your personal data to be treated for these
              secondary purposes, you can: send from this moment an email to{' '}
              <a
                className='content-section-link-privacidad'
                href='mailto:contacto@bpotecnologia.com'
                target='_blank'
                rel='noopener noreferrer'>
                contacto@bpotecnologia.com
              </a>
              .
            </p>
            <p className='content-title-aviso-privacidad'>
              5. Options and means that we offer you to limit the use or
              disclosure of your personal data.
            </p>
            <p className='content-section-aviso-privacidad'>
              In all legally applicable cases, you may limit the use or
              disclosure of your personal data, presenting a request by sending
              an email to{' '}
              <a
                className='content-section-link-privacidad'
                href='mailto:contacto@bpotecnologia.com'
                target='_blank'
                rel='noopener noreferrer'>
                contacto@bpotecnologia.com
              </a>
            </p>
            <p className='content-title-aviso-privacidad'>
              6. Transfer of personal data.
            </p>
            <p className='content-section-aviso-privacidad'>
              Your personal data may be transferred within the territory
              national or abroad (in compliance with the regulation applicable),
              as follows:
              <ul className='content-section-ul-second-aviso-privacidad'>
                <li>
                  <span className='bold'>1.</span> To authorities, such as
                  including but not limited to, the National Institute Electoral
                  (hereinafter, "INE"), organizations or entities government, in
                  compliance with or in relation to the obligations contemplated
                  in the legislation applicable to BPOs, its suppliers,
                  customers or business associates.
                </li>

                <li>
                  <span className='bold'>2.</span> To authorities, such as
                  including, but not limited to, the INE, organizations or
                  government entities, when the transfer is officially required
                  and/or necessary for recognition, exercise or defense of a
                  right of BPO, its suppliers, customers or business associates.
                  in a judicial process.
                </li>
                <li>
                  <span className='bold'> 3.</span> To holding companies and/or
                  affiliates and/or subsidiaries and/or related companies or
                  belonging to BPO for marketing purposes, advertising,
                  promotion, commercial prospecting, process of references,
                  preparation, assessment and analysis of profiles of the Users,
                  of centralized protection of the information, for analytical
                  and statistical purposes and historical record of users and
                  conducting surveys.
                </li>
                <li>
                  <span className='bold'> 4.</span> To third-party acquirers,
                  derived from a corporate BPO restructuring, including, the
                  merger, consolidation, sale, liquidation or transfer of
                  assets, when the transfer is necessary for the maintenance or
                  fulfillment of a relationship between the BPO and the Third
                  parties.
                </li>
                <li>
                  <span className='bold'> 5.</span> To third parties who display
                  and sell products and/or services or agree to carry out the
                  management of an order through the Portals or Sites and the
                  Apps, as well as service providers and allies businesses
                  including financial institutions, when the transfer is
                  necessary for maintenance or for manage, develop, maintain,
                  customize, and improve our Portals or Sites and Apps.
                </li>
                <li>
                  <span className='bold'> 6.</span> Other assumptions
                  established in the Personal Data Legislation, which does not
                  require your consent. If you do not express your refusal for
                  said transfer, which you can do from this moment sending an
                  email to the email contacto@bpotecnologia.com, otherwise we
                  will understand that you have given us your consent.
                </li>
              </ul>
            </p>
            <p className='content-title-aviso-privacidad'>
              7. Means to exercise your rights.
            </p>
            <p className='content-section-aviso-privacidad'>
              In all legally appropriate cases, you may, in at all times and,
              exercise before BPO the following rights regarding your personal
              data:
              <ul className='content-section-ul-aviso-privacidad'>
                <li>
                  <span>
                    Rights of access, rectification, cancellation and
                    opposition.
                  </span>
                </li>
                <li>
                  <span>
                    Revoke the consent granted to BPO for the use of its
                    personal information.
                  </span>
                </li>
                <li>
                  <span>
                    Limit the use or disclosure of your personal data.
                  </span>
                </li>
                <li>
                  <span>
                    Express your refusal to process your data personal with
                    respect to the secondary purposes before mentioned.
                  </span>
                </li>
              </ul>
              For the exercise of said rights regarding your data personal
              information must submit the corresponding request, through duly
              filled out the electronic form that we will put at your provision,
              after receiving your request to the email{' '}
              <a
                className='content-section-link-privacidad'
                href='mailto:contacto@bpotecnologia.com'
                target='_blank'
                rel='noopener noreferrer'>
                contacto@bpotecnologia.com
              </a>{' '}
              and after filling out the form you must send it to the mail email{' '}
              <a
                className='content-section-link-privacidad'
                href='mailto:contacto@bpotecnologia.com'
                target='_blank'
                rel='noopener noreferrer'>
                contacto@bpotecnologia.com
              </a>{' '}
              , with autograph signature at the bottom of the same, establishing
              as matter "Exercise of rights on personal data", and, accompanied
              by the following:
              <ul className='content-section-ul-aviso-privacidad'>
                <li>
                  <span>
                    Copy of the documents proving your identity (current
                    official identification with photograph) or, in its case,
                    legal representation, in accordance with the terms
                    established in the Personal Data Legislation.
                  </span>
                </li>
                <li>
                  <span>
                    Any other element or document that facilitates the location
                    of your personal data.
                  </span>
                </li>
              </ul>
              We will process your request for the exercise of your rights
              regarding personal data within a period not exceeding 20 (twenty)
              business days from its receipt. BPO may extend this term up to 20
              (twenty) additional business days when the case warrants it, prior
              notification. In case your request is appropriate, it will be
              effective within 15 (fifteen) business days after sending the
              response by BPOs.
            </p>
            <p className='content-section-aviso-privacidad'>
              BPO wishes to keep your personal data up to date at all moment.
              Therefore, we request your cooperation to notify us in a timely
              manner of any changes that we should take into account account, to
              incorporate it into our databases.
            </p>
            <p className='content-section-aviso-privacidad'>
              You guarantee and answer, in any case, for the veracity, accuracy,
              validity and authenticity of your personal data or the of any
              third party in its custody or legal representation, in with the
              understanding that BPO will comply with the provisions of the
              applicable legislation in relation to the data of said third
              parties received by BPO.
            </p>
            <p className='content-title-aviso-privacidad'>
              8. Means that allow the collection of personal data in an
              automatic.
            </p>
            <p className='content-section-aviso-privacidad'>
              We inform you that the Portals or Sites, applications or Apps,
              call center services contracted by BPO or Call services BPO Center
              and through digital channels including Social Networks make use of
              cookies and other technologies, through of which it is possible to
              automatically monitor your behavior, provide our products and/or
              services and Give you a personalized experience while using the
              themselves, as well as offer new products and services based on
              your preferences. The personal data collected through These
              technologies are: IP address, websites and sections of the same
              that You visit from the Sites, before the Sites or in pages
              related to the Sites and Apps, features of browser, device
              features, operating system, language preferences, referenced URLs,
              information about behaviors and actions carried out on the Sites
              and the Apps, information about behaviors and actions carried out
              while the Apps is open, dates and times of visits to the Portals
              or Sites or access to the Apps, APIs of third parties and sections
              or content consulted in them and data from location and location
              of the User while the Apps are open.
            </p>

            <p className='content-title-aviso-privacidad'>
              9. Changes to the Privacy Notice.
            </p>
            <p className='content-section-aviso-privacidad'>
              This Privacy Notice may be modified, changes or updates derived
              from new requirements legal, of our own needs for the products
              and/or services we offer, our practices, programs or privacy
              policies, changes in our business model or for other causes. We
              promise to keep you informed about the changes that this Privacy
              Notice may undergo, to through our Portals or Sites, the Apps, or
              any digital channel that technology allows us and in the section
              corresponding to the Privacy Notice of our page{' '}
              <a className='content-section-link-privacidad' href='/'>
                www.bpotecnologia.com
              </a>
              .
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Avisodeprivacidad;
