import React, { useState, useEffect } from 'react';
import '../../../../assets/css/atoms/sections.css';
import Cliente1 from '../../../../assets/img/pages/clientes1.png';
import Cliente2 from '../../../../assets/img/pages/clientes2.png';
import Cliente3 from '../../../../assets/img/pages/clientes3.png';
import Cliente4 from '../../../../assets/img/pages/clientes4.png';
import Cliente5 from '../../../../assets/img/pages/clientes5.png';
import Cliente6 from '../../../../assets/img/pages/clientes6.png';
import Cliente7 from '../../../../assets/img/pages/clientes7.png';
import Cliente8 from '../../../../assets/img/pages/clientes8.png';

const Clientes = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);
  return (
    <>
      {language === 'Spanish' ? (
        <div className='text-center mt-3 container-fluid  animate__animated animate__zoomInLeft animate__bounce animate__delay-3s'>
          <div>
            <h1 className='text-white text-left bold text-center'>
              <p className='mt-6 ptm-121 mb-5'>
                <span className='pink'> Nuestros clientes</span>
              </p>
            </h1>
            <div className='background-white section-client'>
              <div className='row mt-4 mm-0 row-client-2'>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente1}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente2}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente3}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente4}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-4 mm-0 row-client-2'>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente5}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente6}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>

                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente7}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>

                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente8}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center mt-3 container-fluid  animate__animated animate__zoomInLeft animate__bounce animate__delay-3s'>
          <div>
            <h1 className='text-white text-left bold text-center'>
              <p className='mt-6 ptm-121 mb-5'>
                <span className='pink'> Our customers</span>
              </p>
            </h1>
            <div className='background-white section-client'>
              <div className='row mt-4 mm-0 row-client-2'>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente1}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente2}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente3}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente4}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-4 mm-0 row-client-2'>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente5}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente6}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>

                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente7}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>

                <div class='col-sm-3'>
                  <div class='card card-generic2 background-transparent'>
                    <div class='card-body'>
                      <img
                        src={Cliente8}
                        alt='service'
                        className='card-image-generic-response'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Clientes;
