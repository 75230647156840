import React from 'react';
import './../../../assets/css/atoms/cards.css';

const CardsGeneric = ({
  title,
  text,
  src,
  link,
  textButton = 'Conoce más',
}) => {
  return (
    <div class='col-sm-3'>
      <div class='card card-generic'>
        <div class='card-body'>
          <img src={src} alt='solutions' className='card-image-generic' />
          <h5 class='card-title-generic'>{title}</h5>
          <p class='card-generic-text'>{text}</p>
          <button class='card-generic-button'>
            <a href={link}>{textButton}</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardsGeneric;
