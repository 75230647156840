import React, { useState, useEffect } from 'react';
import '../../../../assets/css/atoms/sections.css';
import Button from '../../../atoms/button';
import { Cards1 } from '../../../atoms/cards/cards1';
import Titleh2 from '../../../atoms/title/titleh2';
import img1 from '../../../../assets/img/pages/cubitos.png';
import img2 from '../../../../assets/img/pages/solucion2.png';
import img3 from '../../../../assets/img/pages/agil.png';
import img5 from '../../../../assets/img/pages/datos-en-la-nube.png';
import img6 from '../../../../assets/img/pages/critico.png';
import img7 from '../../../../assets/img/pages/solucion5.png';

import CarouselTools from '../../sections/home/carouselTools';

const Soluciones = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);
  return (
    <>
      {language === 'Spanish' ? (
        <div
          className='text-center mt-3 container-fluid pt-105 ptm-0 ptm-60  animate__animated animate__zoomInLeft animate__bounce'
          id='soluciones'>
          <div className='text-align-center'>
            <div className='mt-5  col-lg-12 mtm-0'>
              <Button />
              <p className='mt-5 text-gray fs-20 font-weight-100 text-analitic'>
                Diseño | Arquitectura | Desarrollo | Infraestructura como código
                | Optimización | Infraestructura | Integración | Análisis de
                datos | Ingeniería de datos | Ciencia de datos | Machine
                Learning | Tableros de control | Reportes | Pruebas
                Automatizadas | Site reliability engineering
              </p>
            </div>
          </div>

          <Titleh2
            text='Contamos con expertos en las siguientes herramientas y plataformas'
            className='title2 mb-50 mt-5'
          />

          <div className='section-tools'>
            <CarouselTools />
          </div>
          <div className='bg-opacity-medium-black container-fluid text-center mt-1'>
            <div className='row justify-content-center pt-20'>
              <Titleh2
                text='Materialice sus proyectos con las mejores tecnologías'
                className='title2 mb-50'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Migración de aplicaciones monolíticas a microservicios desacoplados'
                src={img1}
                width='278'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Infraestructura escalable en la nube mediante contenedores y funciones serverless'
                src={img2}
                width='286'
                classTitle='img-card-material-2'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Desarrollo acelerado de aplicativos mediante procesos de CI/CD'
                src={img3}
                width='250'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Seguridad a nivel infraestructura y microservicios garantizando la integridad de plataformas'
                src={img6}
                width='190'
                classTitle='img-card-material-4'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Análisis de datos mediante herramientas avanzadas para descubrir que puede mejorar'
                src={img7}
                width='262'
                classTitle='card-image-5'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Migración de servicios a la nube, actualización y mejora de plataformas SaaS'
                src={img5}
                width='240'
                classTitle='card-image-6'
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className='text-center mt-3 container-fluid pt-105 ptm-0 ptm-60  animate__animated animate__zoomInLeft animate__bounce'
          id='soluciones'>
          <div className='text-align-center'>
            <div className='mt-5  col-lg-12 mtm-0'>
              <Button />
              <p className='mt-5 text-gray fs-20 font-weight-100 text-analitic'>
                Design | Architecture | Development | Infrastructure as code |
                Optimization | Infrastructure | Integration | Data analysis |
                Data engineering | Data science | Machine learning | Dashboards
                | Reports | Automated Testing | Site reliability engineering
              </p>
            </div>
          </div>

          <Titleh2
            text='We have experts in the following tools and platforms'
            className='title2 mb-50 mt-5'
          />

          <div className='section-tools'>
            <CarouselTools />
          </div>
          <div className='bg-opacity-medium-black container-fluid text-center mt-1'>
            <div className='row justify-content-center pt-20'>
              <Titleh2
                text='Materialize your projects with the best technologies'
                className='title2 mb-50'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Migration of monolithic applications to decoupled microservices'
                src={img1}
                width='278'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Scalable infrastructure in the cloud through containers and serverless functions.'
                src={img2}
                width='286'
                classTitle='img-card-material-2'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Accelerated application development through CI/CD processes'
                src={img3}
                width='250'
              />

              <Cards1
                className='col-md-4 cards-1'
                text='Infrastructure and microservices security ensuring platform integrity'
                src={img6}
                width='190'
                classTitle='img-card-material-4'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Data analytics using advanced tools to discover what can be improved'
                src={img7}
                width='262'
                classTitle='card-image-5'
              />
              <Cards1
                className='col-md-4 cards-1'
                text='Migration of services to the cloud, updating and improvement of SaaS platforms.'
                src={img5}
                width='240'
                classTitle='card-image-6'
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Soluciones;
