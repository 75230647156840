import React from 'react';
import '../../../../assets/css/atoms/sections.css';
import '../../../../assets/css/atoms/buttons.css';
import Titleh2 from '../../../atoms/title/titleh2';

const Result = () => {
  return (
    <div className='container-fluid mt-5 pt-52'>
      <div className='row  mt-4 mb-4 justify-content-center'>
        <div className='col-sm-6 text-align-end'>
          <img
            src='https://static.wixstatic.com/media/ad9488_ab7e9af4082b45c7964f375fcf808e47~mv2.jpg/v1/fill/w_435,h_434,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/bulls_edited_edited.jpg'
            alt='result'
            width='384'
            className='img-result'
          />
        </div>
        <div className='col-sm-6'>
          <p className='title-result'>
            Resultados Garantizados. <br />
            Compromiso de tarifa fija.
          </p>
          <p className='text-result'>
            Entendemos las formas mejores y más eficientes de alcanzar sus
            objetivos de datos y lo garantizamos. Trabajando con nosotros en un
            proyecto claramente definido y bien definido, nuestro equipo
            entregará resultados garantizados a un precio fijo. Elimine las
            conjeturas sobre el costo real de un compromiso de consultoría y
            agilice una implementación exitosa.
          </p>
          <div className='text-center button-section-result '>
            <a href='/contacto' className='text-white'>
              Nuestra Metodología
            </a>
          </div>
        </div>
        <div className='col-sm-4 mt-5  col-result'>
          <p className='title-result'>
            Entrega flexible. Equipo de datos
            <br />
            bajo demanda
          </p>
          <p className='text-result'>
            Estamos listos para trabajar en sus términos. Cuando su personal
            necesite ayuda adicional, podemos apoyarlo con un modelo flexible
            basado en retención para fortalecer su equipo. Con este enfoque
            elástico, podemos habilitar a su equipo de datos, ampliando o
            reduciendo según sea necesario.
          </p>
        </div>
        <div className='col-sm-5  mt-5  text-align-end mmt-0'>
          <img
            src='https://static.wixstatic.com/media/ad9488_1a25c5d786594364a7cb15481394b104~mv2.jpg/v1/fill/w_435,h_435,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/flexible%20data%20comp_edited_edited.jpg'
            alt='result'
            width='384'
            className='img-result'
          />
        </div>
      </div>
      <div className='row  mt-5 mb-4 justify-content-center'>
        <div className='col-sm-6 text-align-end'>
          <img
            src='https://static.wixstatic.com/media/ad9488_0d83102e8cec4b0a9eda54d429b6286a~mv2.jpg/v1/fill/w_435,h_434,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/chessho_edited.jpg'
            alt='result'
            width='384'
            className='img-result'
          />
        </div>
        <div className='col-sm-6'>
          <p className='title-result'>Liderazgo Estratégico Ejecutivo</p>
          <p className='text-result'>
            Es hora de empezar a jugar al ajedrez. <br />
            Trabajaremos con su equipo de liderazgo ejecutivo para ayudar a
            guiar las conversaciones estratégicas y desarrollar un plan de juego
            ejecutable para lograr análisis de datos del siguiente nivel.
            <br />
            Después de ayudarlo a crear una hoja de ruta hacia un manual de
            análisis de negocios más exitoso, usted decide cuál es la mejor
            manera de proceder. Apoyaremos sus iniciativas según sea necesario.
          </p>
        </div>
        <div className='col-sm-4 mt-5  col-result'>
          <p className='title-result'>Somos fáciles de trabajar con</p>
          <p className='text-result'>
            Independientemente de cómo desee comprometerse con nosotros,
            contamos con el equipo adecuado y términos flexibles para que esto
            suceda. Trabajando juntos, podemos recomendar las mejores formas de
            alcanzar sus objetivos de análisis de datos.
          </p>
          <div className='text-center button-section-result mmb-2'>
            <a href='/contacto' className='text-white'>
              Programar consulta gratuita
            </a>
          </div>
        </div>
        <div className='col-sm-5  mt-5  text-align-end mmt-0'>
          <img
            src='https://static.wixstatic.com/media/ad9488_784cba52b0b24ae583e05e790822275b~mv2.jpg/v1/fill/w_435,h_434,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/stackinggreen_edited.jpg'
            alt='result'
            width='384'
            className='img-result'
          />
        </div>
      </div>
      <div>
        <Titleh2
          text='Una carta abierta de SCi'
          className='title-result-1 mb-4'
        />
        <div className='row justify-content-center'>
          <div className='col-md-8 content-result-1'>
            <p>
              Fundamos Silver Creek Insights (SCi) para simplificar el complejo
              mundo del análisis de datos. Con una vasta experiencia al servicio
              de clientes empresariales, comprendemos los desafíos que impiden
              que las empresas accedan y exploren con éxito los datos para
              descubrir información clave. Oportunidades perdidas que podrían
              conducir a ventajas competitivas reales, optimizando todo, desde
              la eficiencia operativa hasta un equipo de ventas más rentable.
            </p>
            <p>
              En un mundo de crecimiento exponencial de datos y un multiverso de
              silos, la mayoría de las empresas continúan investigando
              información obsoleta utilizando métodos obsoletos. A pesar de
              invertir en las últimas y mejores tecnologías, las personas se
              sienten atrapadas para siempre en informes, paneles y archivos de
              Excel obsoletos.
            </p>
            <p>
              Fundamos Silver Creek Insights (SCi) para simplificar el complejo
              mundo del análisis de datos. Con una vasta experiencia al servicio
              de clientes empresariales, comprendemos los desafíos que impiden
              que las empresas accedan y exploren con éxito los datos para
              descubrir información clave. Oportunidades perdidas que podrían
              conducir a ventajas competitivas reales, optimizando todo, desde
              la eficiencia operativa hasta un equipo de ventas más rentable.
            </p>
            <p>
              En un mundo de crecimiento exponencial de datos y un multiverso de
              silos, la mayoría de las empresas continúan investigando
              información obsoleta utilizando métodos obsoletos. A pesar de
              invertir en las últimas y mejores tecnologías, las personas se
              sienten atrapadas para siempre en informes, paneles y archivos de
              Excel obsoletos.
            </p>
            <p>¡Salud por tu éxito!</p>
            <p className='mb-4'>
              Daniel H Pham
              <br /> Director ejecutivo, Silver Creek Insights, Inc.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
