import React, { useState, useEffect } from 'react';
import '../../../../assets/css/atoms/sections.css';
import 'animate.css';

const Nosotros = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState('');
  useEffect(() => {
    if (browserLanguage.includes('es')) {
      setLanguage('Spanish');
    } else if (browserLanguage.includes('en')) {
      setLanguage('English');
    } else {
      setLanguage('English');
    }
  }, [browserLanguage]);
  return (
    <>
      {language === 'Spanish' ? (
        <div className='text-center mt-3 container-fluid' id='nosotros'>
          <div className='mb-5 mt-5 animate__animated animate__fadeInDown animate__bounce animate__delay-2s'>
            <h1 className='text-white text-left bold text-center'>
              <p className='pt-121 ptm-60'>
                ¿Quienes somos?
                <p className='pink second-about'>
                  <br />
                  Somos un grupo de expertos en tecnología enfocados en las
                  ultimas tendencias de la industria, apoyándonos en las mejores
                  plataformas y soluciones
                </p>
              </p>
            </h1>
            <div className='row mt-5 mm-0'>
              <div className='col-md-12 text-optimization'>
                <p className='text-bold pink'>Misión</p>
                <p className='p-optimization'>
                  Proporcionar las tecnologías más innovadoras a medida de las
                  necesidades empresariales, con el objetivo de incrementar su
                  competitividad y productividad. Para ello implementamos
                  soluciones prácticas adaptadas a sus necesidades y con nuevas
                  soluciones creativas. Nuestra base parte del aprovechamiento
                  de las nuevas tecnologías.
                </p>
                <br />
                <p className='text-bold pink'>Visión</p>
                <p className='p-optimization'>
                  Estar comprometidos con los problemas de nuestros clientes de
                  forma transparente y eficaz para convertirnos en su socio de
                  confianza. Ser una empresa de referencia, que camina con el
                  cambio de la tecnología, medio ambiente y social,
                  posicionándonos comó un referente en todas las región de
                  Latinoamérica. Esta labor se debe desempeñar de forma ética y
                  satisfactoria para nosotros, nuestros clientes, socios,
                  empleados y el resto de la sociedad.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center mt-3 container-fluid' id='nosotros'>
          <div className='mb-5 mt-5 animate__animated animate__fadeInDown animate__bounce animate__delay-2s'>
            <h1 className='text-white text-left bold text-center'>
              <p className='pt-121 ptm-60'>
                Who are we?
                <p className='pink second-about'>
                  <br />
                  We are a group of technology experts focused on the latest
                  industry trends, relying on the best platforms and solutions.
                </p>
              </p>
            </h1>
            <div className='row mt-5 mm-0'>
              <div className='col-md-12 text-optimization'>
                <p className='text-bold pink'>Mission</p>
                <p className='p-optimization'>
                  To provide the most innovative technologies customized to your
                  business needs, with the objective of increasing your
                  competitiveness and productivity. To do so, we implement
                  practical solutions adapted to your needs and with new
                  creative solutions. Our basis is founded on the use of new
                  technologies.
                </p>
                <br />
                <p className='text-bold pink'>Vision</p>
                <p className='p-optimization'>
                  To be committed to the problems of our customers in a
                  transparent and effective way to become their trusted partner.
                  To be a company of reference that moves with the change of
                  technology, environment and society, positioning us as a
                  reference in all the Latin American region. This work must be
                  performed in an ethical and satisfactory way for us, our
                  customers, partners, employees and the rest of society.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Nosotros;
