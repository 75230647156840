import React from 'react';
import '../../../../assets/css/atoms/sections.css';
import '../../../../assets/css/general.css';
import CardsGeneric from '../../../atoms/cards/cardsgeneric';
import Solution1 from '../../../../assets/img/pages/electronic-storefront-concept-illustration-computer-with-awning-man-pushing-cart-to-online-store-thumbnail.jpg';
import Solution2 from '../../../../assets/img/pages/whatsapp-3012138_960_720.png';
import Solution3 from '../../../../assets/img/pages/customer-relationship-management-illustration-thumbnail.jpg';
import Solution4 from '../../../../assets/img/pages/training-tablet-touch-screen-meaning-education-development-and-learning.jpg';
import Solution5 from '../../../../assets/img/pages/backpack-bag-beard-cars-thumbnail.jpg';
import Efect1 from '../../../atoms/effectsText/efect1/efect1';
const Contens = () => {
  return (
    <div>
      <Efect1 value='SOLUCIONES DIGITALES      PaaS y SaaS' />
      <div class='container-static'>
        <div class='row content-product ml-0 mr-0 col-sm-12'>
          <CardsGeneric
            src={Solution1}
            title='Programas de lealtad y gestión de puntos'
            link='/'
          />
          <CardsGeneric
            src={Solution2}
            title='Gestión de notificaciones en diferentes medios'
            link='/'
          />
          <CardsGeneric
            src={Solution3}
            title='Clientes, cuentas, productos, inventarios, ventas'
            link='/'
          />
          <CardsGeneric
            src={Solution4}
            title='Plataforma de capacitación, elearning, aula virtual y digital'
            link='/'
          />
          <CardsGeneric
            src={Solution5}
            title='Gestión de visitas con geolocalización en tiempo real'
            link='/'
          />
        </div>
      </div>
    </div>
  );
};

export default Contens;
